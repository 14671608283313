const password = 'demo'

const authorized_users = {
    "nie": "nie",
    "yong": "yong",
    "geyu": "geyu",
    'demo': 'demo',
    'zhengyuan': 'zhengyuan'
}
export default authorized_users;
