import React from 'react';
import './index.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Dialogue from './pages/Dialogue';
import AboutPage from './pages/AboutPage';
import DialogueDev from './pages/Dialogue_dev';
import DialogueUs from './pages/Dialogue_us';
import Navbar from './components/Navbar';
function App() {
  
  return (
    <BrowserRouter> {/* Wrap your app with the router */}
      {/* <Navbar /> */}
      <Routes>
      <Route path="/" element={<Navigate to="/dev" replace />} />
        <Route path="/chat" element={<Dialogue />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/dev" element={<DialogueDev />} />
        <Route path="/us" element={<DialogueUs />} />
        {/* <Route path="/settings" element={<SettingsPage />} />  */}
      </Routes>
    </BrowserRouter>
 );
  
}

export default App;
