const password = 'demo'

const authorized_users = {
	    "nie": "nie",
	    "yong": "yong",
	    "geyu": "geyu",
	    'demo': 'demo',
	    'zhengyuan': 'zhengyuan',
	    "S01": "3702",
	    "S02": "9000",
	    "S03": "9221",
	    "S04": "2956",
	    "S05": "5285",
	    "S06": "5195",
	    "S07": "7795",
	    "S08": "6503",
	    "S09": "6179",
	    "S10": "1799",
	    "S11": "9581",
	    "S12": "7822",
	    "S13": "9280",
	    "S14": "5029",
	    "S15": "7447",
	    "S16": "8043",
	    "S17": "1222",
	    "S18": "2198",
	    "S19": "1214",
	    "S20": "5041",
	    "S21": "8921",
	    "S22": "5809",
	    "S23": "4237",
	    "S24": "6551",
	    "S25": "1226",
	    "S26": "5422",
	    "S27": "3311",
	    "S28": "9760",
	    "S29": "4223",
	    "S30": "1819",
	    "S31": "8652",
	    "S32": "1737",
	    "S33": "1076",
	    "S34": "6246",
	    "S35": "3677",
	    "S36": "8762",
	    "S37": "1786",
	    "S38": "7142",
	    "S39": "7079",
	    "S40": "8820",
	    "S41": "8095",
	    "S42": "7035",
	    "S43": "5277",
	    "S44": "4092",
	    "S45": "1879",
	    "S46": "2725",
	    "S47": "5904",
	    "S48": "2913",
	    "S49": "3660",
	    "S50": "4233",
	    "S51": "1429",
	    "S52": "7884",
	    "S53": "3288",
	    "S54": "4640",
	    "S55": "8880",
	    "S56": "3495",
	    "S57": "2145",
	    "S58": "3904",
	    "S59": "1905",
	    "S60": "7395",
	    "S61": "4150",
	    "S62": "4953",
	    "S63": "5141",
	    "S64": "3995",
	    "S65": "7012",
	    "S66": "3899",
	    "S67": "2725",
	    "S68": "2885",
	    "S69": "6553",
	    "S70": "5624",
	    "S71": "5669",
	    "S72": "9927",
	    "S73": "5761",
	    "S74": "4785",
	    "S75": "5526",
	    "S76": "1085",
	    "S77": "6131",
	    "S78": "1113",
	    "S79": "5875",
	    "S80": "2217"
}

export default authorized_users;
