export const image_map = {
    'nie_image_1': 'alfankhoi_img_20.png',
    'nie_image_2': 'auliacas_img_13.png',
    'nie_image_3': 'daudal_img_17.png',
    'nie_image_4': 'daudal_img_20.png',
    'nie_image_5': 'diahlara_img_1.png',
    'nie_image_6': 'diahlara_img_5.png',
    'nie_image_7': 'dreaam_img_18.png',
    'nie_image_8': 'dreaam_img_19.png',
    'nie_image_9': 'fauzyros_img_17.png',
    'nie_image_10': 'gracetan_img_15.png',
    'nie_image_11': 'gracetan_img_21.png',
    'nie_image_12': 'hilmisar_img_8.png',
    'nie_image_13': 'jakanur_img_6.png',
    'nie_image_14': 'kevinal_img_7.png',
    'nie_image_15': 'milanagu_img_6.png',
    'nie_image_16': 'muhaizzal_img_4.png',
    'nie_image_17': 'nabiaul_img_1.png',
    'nie_image_18': 'nicpras_img_4.png',
    'nie_image_19': 'qonitamar_img_10.png',
    'nie_image_20': 'qonitamar_img_8.png',
    'nie_image_21': 'rizkasal_img_2.png',
    'nie_image_22': 'aisyahmr_img_05.png',
    'nie_image_23': 'alfankhoi_img_23.png',
    'nie_image_24': 'angtl_img_03.png',
    'nie_image_25': 'auliacas_img_17.png',
    'nie_image_26': 'ayuhafa_img_26.png',
    'nie_image_27': 'bayukris_img_06.png',
    'nie_image_28': 'bayukris_img_21.png',
    'nie_image_29': 'daffasa_img_07.png',
    'nie_image_30': 'daudal_img_18.png',
    'nie_image_31': 'diahsek_img_21.png',
    'nie_image_32': 'dreaam_img_23.png',
    'nie_image_33': 'ikjed_img_09.png',
    'nie_image_34': 'kevinal_img_23.png',
    'nie_image_35': 'liviag_img_17.png',
    'nie_image_36': 'meldanur_img_01.png',
    'nie_image_37': 'nabiaul_img_08.png',
    'nie_image_38': 'nicpras_img_08.png',
    'nie_image_39': 'rariind_img_16.png',
    'nie_image_40': 'samsulm_img_03.png',
    'nie_image_41': 'vivianphoa_img_02.png'
}
