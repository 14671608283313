export const service_conf_dev = {
    "TTS_SPEAK_SERVICE" : {'en': 'https://edgc.astarwiz.com/speak', 'zh': 'https://edgc.astarwiz.com/speak', 'ma': 'https://edgc.astarwiz.com/speak', "ta": 'https://edgc.astarwiz.com/speak'},
    "TTS_WAVE_SERVICE" : {'en': 'https://edgc.astarwiz.com/wave', 'zh': 'https://edgc.astarwiz.com/wave', 'ma': 'https://edgc.astarwiz.com/wave', "ta": 'https://edgc.astarwiz.com/wave'},
    "EVAL_SERVICE": 'https://astarwiz.com:9202/evaluate',
    "TTS_FILE_SERVICE": 'https://edgc.astarwiz.com/tts',
    "DIALOGUE_SERVICE": {"A": "https://edgc.astarwiz.com/dialogue_backend_a", "B": "https://edgc.astarwiz.com/dialogue_backend_b", "C": "https://edgc.astarwiz.com/dialogue_backend_c"}, 
    "ASR_SERVICE": "https://edgc.astarwiz.com/asr",
    // "DIALOGUE_SERVICE": "https://10.2.56.194:2333/dialogue_backend",
    // "ASR_SERVICE": "https://10.2.56.194:9898/asr"
}

