import React, { useEffect, useRef, useState} from 'react';
import useRecorder from './useRecorder'; // Update this import path
import { Button, Box, Typography, Paper, useMediaQuery} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import UploadFileIcon from '@mui/icons-material/UploadFile'; 
import { useComponentSize } from './useComponentSize';

const Recorder = ({ handleAudio, handleStart, isTTSGenerating, isEvaluating, isFinished}) => {
    const [audioURL, setAudioURL, isRecording, startRecording, stopRecording, deviceStatus] = useRecorder();
    const [uploadedFile, setUploadedFile] = useState(null);
    const boxRef = useRef(null);
    const { width } = useComponentSize(boxRef); // Use the custom hook

    useEffect(() => {
        if (audioURL) {
            console.log('Audio set.')
            handleAudio(audioURL);
        }
    }, [audioURL]);

    const handlePress = () => {
        console.log(deviceStatus)
        startRecording();
        if (deviceStatus === 'GRANTED') {
            handleStart();
        }
    };

    const handleRelease = () => {
        stopRecording();
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setUploadedFile(url);
            handleAudio(url); // Handle the uploaded audio file
        }
    };

    return (
        <Box 
            ref={boxRef} 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                gap: 2,
                width: '50%',
                margin: '0 auto'
            }}
        >
            <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onMouseDown={handlePress}
                    onMouseUp={handleRelease}
                    onTouchStart={handlePress}
                    onTouchEnd={handleRelease}
                    disabled={isTTSGenerating || isEvaluating}
                    sx={{ 
                        marginBottom: 2, 
                        marginLeft: 2,
                        width: '100%',
                        borderRadius: '50px',
                    }}
                    startIcon={<MicIcon />}
                >
                    {width > 500 ? 'Hold to Record' : null}
                </Button>

                <input
                    accept="audio/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={handleFileUpload}
                />
                {/* <label htmlFor="raised-button-file">
                    <Button 
                        variant="contained" 
                        component="span" 
                        startIcon={<UploadFileIcon />}
                        disabled={isRecording || isTTSGenerating || isEvaluating}
                        sx={{ marginBottom: 2, marginRight: 2}} 
                    >
                        {width > 500 ? 'Upload Audio' : null}
                    </Button>
                </label> */}
            </Box>
            
            {/* {isRecording && (
                <Typography color="error">
                    Recording...
                </Typography>
            )} */}
        </Box>
    );
};

export default Recorder;
