export const image_map = {
    'image_004': 'image004.jpg',
    'nie_image_1': '1_DefiraFA_img_25.jpg',
    'nie_image_2': '1_DiahLara_img_2_Edited.jpg',
    'nie_image_3': '2_FarichaNur_img_16_Edited.jpg',
    'nie_image_4': '2_FauzyRos_img_10.jpg',
    'nie_image_5': '3_(backup)AdityaBY_img_14_Edited.jpg',
    'nie_image_6': '3_(Backup)BayuKris_img_13.jpg',
    'nie_image_7': 'adityaby_img_42.png',
    'nie_image_8': 'Alfankhoi_img_20.png',
    'nie_image_9': 'anakagu_img_26_Edited.jpg',
    'nie_image_10': 'Bedtest_Image_v2.jpg',
    'nie_image_11': 'DiahLara_img_1.png',
    'nie_image_12': 'DiahLara_img_5_Edited.jpg',
    'nie_image_13': 'elsyad_img_46.png',
    'nie_image_14': 'farichanur_img_05.png',
    'nie_image_15': 'IKJed_img_09_Edited.jpg',
    'nie_image_16': 'inashana_img_20.png',
    'nie_image_17': 'khansaans_img_10_Edited.png',
    'nie_image_18': 'MilanAgu_img_6.png',
    'nie_image_19': 'najmazni_img_06.png',
    'nie_image_20': 'nmnadia_img_43.png',
    'nie_image_21': 'rikazkhan_img_11.png',
    'nie_image_22': 'Testbed_Playground.jpg',
    'nie_image_23': 'veliciapc_img_07.png',
    'nie_image_24': 'vivianphoa_img_41_Edited.jpg',
    'nie_image_25': 'yemimau_img_39.png'
}

export const image_schedule = [
    'nie_image_23', 'nie_image_24', 'nie_image_25'
]
